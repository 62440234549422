
import store from '@/store'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
   * @param {number} time
   * @param {string} option
   * @returns {string}
   */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
        1 +
        '月' +
        d.getDate() +
        '日' +
        d.getHours() +
        '时' +
        d.getMinutes() +
        '分'
    )
  }
}

/**
   * @param {string} url
   * @returns {Object}
   */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
        decodeURIComponent(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')
          .replace(/\+/g, ' ') +
        '"}'
  )
}

export const getOssUrl = async url => {
  if (!url || url.length === 0 || url.indexOf('/') === -1) return
  const newTime = Date.now()
  if (store.state.global.OssExpiration - newTime <= 0) {
    await store.dispatch('global/getOssStore')
  }
  // console.log(3);

  const index = url.lastIndexOf('/')
  // let aa = store.getters.ossStore.signatureUrl(url.slice(index+1))
  // console.log(4);
  return store.getters.ossStore.signatureUrl(url.slice(index + 1))
}

export function checkLoanPrice(value) {
  value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
  value = value.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
  if (value.indexOf('.') < 0 && value !== '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    value = parseFloat(value)
  }
  return value
}

// 限制只能输入字母和数字
export function checkNumAndLetter(value) {
  return value.replace(/[^A-Za-z0-9]/ig, '')
}

// 获取所有本地存储的key
export function getStorageInfo() {
  const keys = []
  for (let i = 0; i < localStorage.length; i++) {
    keys.push(localStorage.key(i))
  }
  return keys
}
// 根据key获取本地存储
export function getStorageSync(key) {
  return JSON.parse(localStorage.getItem(key))
}
// 根据key存储本地
export function setStorageSync(key, datas) {
  return localStorage.setItem(key, JSON.stringify(datas))
}

// 跳转该元素的滚动条底部
export function goScrollBottom(element) {
  const list = document.getElementById(element)
  list.scrollTo({ top: list.scrollHeight, behavior: 'smooth' })
}
// 将20231010格式的日期转为 key 格式 例 key - ： 2023-10-10
export function idTimeToDate(time, key) {
  return `${time.slice(0, 4)}${key}${time.slice(4, 6)}${key}${time.slice(6, 8)}`
}

export function base64toFile(urlData, fileName) {
  const arr = urlData.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bytes = atob(arr[1])
  let n = bytes.length
  const ia = new Uint8Array(n)
  while (n--) {
    ia[n] = bytes.charCodeAt(n)
  }
  return new File([ia], fileName, { type: mime })
}

