import request from '@/utils/request'
import qs from 'qs'
const baseApi = '/api-product'

// 查询已激活的地区,或所有城市
export function selectAllCity(params) {
  return request({
    url: `${baseApi}/sys_region/selectAllCity`,
    method: 'get',
    params
  })
}
// 获取准入条件
export function getDictUserInfoByType(params) {
  return request({
    url: `${baseApi}/sysdict/getDictUserInfoByType`,
    method: 'get',
    params
  })
}
// 根据经理人和条件获取产品详情
export function getProductDetailBySalesman(params) {
  return request({
    url: `${baseApi}/product/getProductDetailBySalesman`,
    method: 'get',
    params
  })
}
// 根据订单 id 获取产品详情
export function getProductDetailByOrderId(params) {
  return request({
    url: `${baseApi}/product/getProductDetailByOrderId`,
    method: 'get',
    params
  })
}
// 根据城市码获取区信息
export function getRegionByCityCode(params) {
  return request({
    url: `${baseApi}/sys_region/getRegionByCityCode`,
    method: 'get',
    params
  })
}
// 选定经理人
export function matchConfirm(data) {
  return request({
    url: `${baseApi}/users/orderPre/matchConfirm`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取人脸识别地址
export function h5bioAssayUrl(data) {
  return request({
    url: `${baseApi}/contract/h5bioAssayUrl`,
    method: 'post',
    data
  })
}

// 获取 - 订单进度状态
export function getOrderAllStatus(params) {
  return request({
    url: `${baseApi}/order/orderAllStatus`,
    method: 'get',
    params
  })
}
// 约面谈时间判断
export function judgeOrderFaceDate(data) {
  return request({
    url: `${baseApi}/sales/orderFace/judgeOrderFaceDate`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 保存/发起(预约订单、约面谈)
export function orderFaceSave(data) {
  return request({
    url: `${baseApi}/sales/orderFace/save`,
    method: 'post',
    data
  })
}
// 根据订单和材料类型获取需求材料
export function getMaterialImgByOrder(params) {
  return request({
    url: `${baseApi}/materialimg/getMaterialImgByOrder`,
    method: 'get',
    params
  })
}
// 获取需要上传的需求材料字段
export function getDemandMaterialByOderAndType(data) {
  return request({
    url: `${baseApi}//demandmaterial/getDemandMaterialByOderAndType`,
    method: 'post',
    data
  })
}
// 批量新增需求材料图片
export function saveMaterial(data) {
  return request({
    url: `${baseApi}/materialimg/saveMaterial`,
    method: 'post',
    data
  })
}
// 批量新增需求材料图片
export function getFirstTrialStatus(params) {
  return request({
    url: `${baseApi}/order/getFirstTrialStatus`,
    method: 'get',
    params
  })
}
// 获取方案列表（没有分页）
export function getOrderPlanList(params) {
  return request({
    url: `${baseApi}/sales/orderPlan/list`,
    method: 'get',
    params
  })
}
// 确认方案
export function confirmPlan(data) {
  return request({
    url: `${baseApi}/users/orderPlan/confirmPlan`,
    method: 'post',
    data
  })
}
// 获取面签材料产品列表
export function getProductsByMainOrder(params) {
  return request({
    url: `${baseApi}/product/getProductsByMainOrder`,
    method: 'get',
    params
  })
}
// 获取我的经理人订单
export function getMyManagerPersonal(params) {
  return request({
    url: `${baseApi}/users/order/getMyManagerPersonal`,
    method: 'get',
    params
  })
}
// 进度 - 订单进度列表
export function processList(params) {
  return request({
    url: `${baseApi}/orderNode/processList`,
    method: 'get',
    params
  })
}
// 获取指定类型所有字典（树形结构）
export function getAllByCategory(params) {
  return request({
    url: `${baseApi}/sysdict/getAllByCategory`,
    method: 'get',
    params
  })
}
// 获取 - 获取最新资质信息(每个类型的都获取最新的一张，不管多少订单)
export function getFirstNewMaterialImg(params) {
  return request({
    url: `${baseApi}/materialimg/getFirstNewMaterialImg`,
    method: 'get',
    params
  })
}
// 获取 - 用户业务员关(用户和业务员是否产生过订单)
export function getUserSalesRelation(params) {
  return request({
    url: `${baseApi}/users/order/getUserSalesRelation`,
    method: 'get',
    params
  })
}
// 修改贷款状态 loanStatus 贷款状态 0-默认无操作，1-继续贷款，2-结束贷款  orderId 订单id
export function modifyLoanStatus(data) {
  return request({
    url: `${baseApi}/order/modifyLoanStatus`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 平台服务费
export function getplatformFee(params) {
  return request({
    url: `${baseApi}/sales/order/getplatformFee`,
    method: 'get',
    params
  })
}
// 验证是否是第一次打开订单
export function verifyIfItSTheFirstTime(params) {
  return request({
    url: `${baseApi}/order/verifyIfItSTheFirstTime`,
    method: 'get',
    params
  })
}
