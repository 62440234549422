<template>
  <div class="interview">
    <div class="form">
      <div class="group">
        <div class="title">面谈时间</div>
        <div class="cont" @click="goSelcetData()">
          <div v-if="form.faceTime" class="nt date">{{ showTime }}</div>
          <div v-else class="nt">请选择时间</div>
          <div><van-icon name="arrow" /></div>
        </div>
      </div>
      <div class="group">
        <div class="title">面谈地址</div>
        <div class="cont">
          <div class="tt">
            <van-field
              v-model="form.address"
              border
              placeholder="请输入面谈地址"
            />
          </div>
          <div class="ad" @click="goChatLocation"><van-icon name="location" /></div>
        </div>
      </div>
      <div class="group">
        <div class="title">备 注</div>
        <div class="cont">
          <div class="textarea">
            <van-field
              v-model="form.remark"
              type="textarea"
              border
              placeholder="请输入备注"
              maxlength="200"
              show-word-limit
              :autosize="{ minHeight: 150 }"
            />
          </div>
        </div>
      </div>
      <div class="bottom">
        <van-button
          round
          block
          type="info"
          color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
          style="margin-bottom: 20px;"
          @click="goFace"
        >
          发送
        </van-button>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dataConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { parseTime } from '@/utils/index'
import { mapGetters } from 'vuex'
import { judgeOrderFaceDate, orderFaceSave, getOrderAllStatus } from '@/api/product'
export default {
  name: 'ChatInterview',
  data() {
    return {
      form: {
        faceTime: '',
        address: '',
        remark: '',
        userType: 1,
        opSource: 5,
        orderId: ''
      },
      showTime: '',
      currentDate: '',
      minDate: new Date(),
      maxDate: '',
      show: false,
      locationInfo: null,
      locationLookImg: '',
      time: null
    }
  },
  computed: {
    ...mapGetters([
      'selectLocation'
    ])
  },
  mounted() {
  },
  async activated() {
    if (this.time * 1 !== this.$route.query.time * 1) {
      //
      this.locationInfo = null
      this.form.orderId = this.$route.query.orderId
      this.currentDate = new Date()
      const year = this.currentDate.getFullYear() * 1 + 70
      const month = this.currentDate.getMonth() * 1 + 1
      const day = this.currentDate.getDate()
      this.maxDate = new Date(`${year}/${month}/${day}`)
      this.time = this.$route.query.time
    } else {
      console.log(this.selectLocation, 'select')
      if (this.selectLocation) {
        this.locationInfo = { ...this.selectLocation }
        // this.locationLookImg = await getOssUrl(this.locationInfo.locationImg) 显示定位预览图片
        this.form.address = this.selectLocation.address
        console.log(this.locationLookImg, 'look')
        this.$store.dispatch('user/setSelectLocation', null)
      }
    }
  },
  methods: {
    dataConfirm(time) {
      this.form.faceTime = new Date(time).getTime()
      this.showTime = parseTime(time, '{y}-{m}-{d}')
      this.show = false
    },
    goSelcetData() {
      this.currentDate = this.form.faceTime ? new Date(this.form.faceTime) : new Date()
      this.show = true
    },
    goChatLocation() {
      this.$router.push({ name: 'chatLocation', query: { isSelect: true }})
    },
    async goFace() {
      if (this.form.faceTime === '') return this.$toast('请选择面谈时间！')
      if (this.form.address === '') return this.$toast('请输入面谈地址！')
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await judgeOrderFaceDate({ orderId: this.form.orderId, faceTime: this.form.faceTime })
      const flag = res.datas
      if (flag) {
        const res = await getOrderAllStatus({ orderId: this.form.orderId, saleId: this.$route.query.saleId })
        await orderFaceSave(this.form)
        loading.clear()
        const signStatus = res.datas.signStatus
        const isSigned = signStatus === 3 || signStatus === 4 ? 1 : 0
        const locationImg = this.locationInfo && this.locationInfo.locationImg || ''
        const longitude = this.locationInfo && this.locationInfo.lng || ''
        const latitude = this.locationInfo && this.locationInfo.lat || ''
        const faceInfo = { status: true, orderTime: parseTime(this.form.faceTime), address: this.form.address, remark: this.form.remark, locationImg, longitude, latitude, isSigned }
        this.$store.dispatch('user/setFaceInfo', faceInfo)
        this.$router.go(-1)
      } else {
        this.$toast(res.resp_msg)
      }
      console.log(res, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.interview{
  padding: 16px;
  .form {
    .group {
      display: flex;
      align-items: center;
      padding-bottom: 26px;
      .van-cell {
        padding: 0;
      }
      .title {
        width: 100px;
        font-size: 14px;
        color: #2E3135;
      }
      .cont {
        flex: 1;
        display: flex;
        justify-content: space-between;
        .nt {
          color: #CCCCCC;
          &.date {
            color: #2E3135;
          }
        }
        .ad {
          display: flex;
          align-items: center;
          color: #FF7700;
        }
        .textarea {
          flex: 1;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
  }
}
.location-img {
  width: 100%;
  height: 200px;
  background-size: 100%;
  background-position: center;
}
</style>
